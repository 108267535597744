<template>
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 13V2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V13H0ZM6 3.5C6 3.22386 6.22386 3 6.5 3H7.5C7.77614 3 8 3.22386 8 3.5C8 3.77614 7.77614 4 7.5 4H6.5C6.22386 4 6 3.77614 6 3.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 15V18C0 19.1046 0.89543 20 2 20H12C13.1046 20 14 19.1046 14 18V15H0ZM7 18C7.55228 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class BookingApp extends Vue {}
</script>
